$(function () {
	$('[data-toggle="tooltip"]').tooltip()
})

/*

(function ($) {
	$.fn.newsticker = function (opts) {
		// default configuration
		var config = $.extend({}, {
			height: 60,
			speed: 800,
			start: 0,
			interval: 5000
		}, opts);

		// main function
		function init(obj) {
			var dNewsticker = obj,
				dFrame = dNewsticker.find('.newsticker-list'),
				dItem = dFrame.find('.newsticker-item'),
				dNext,
				stop = false;

			dItem.eq(0).addClass('current');

			setInterval(function () {
				if (!stop) {
					var dCurrent = dFrame.find('.current');

					dFrame.animate({
						top: '-=' + config.height + 'px'
					}, config.speed, function () {
						dNext = dFrame.find('.current').next();
						dNext.addClass('current');
						dCurrent.removeClass('current');
						dCurrent.clone().appendTo(dFrame);
						dCurrent.remove();
						dFrame.css('top', config.start + 'px');
					});
				}
			}, config.interval);

			dNewsticker.on('mouseover mouseout', function (e) {
				if (e.type == 'mouseover') {
					stop = true;
				} else { // mouseout
					stop = false;
				}
			});
		}

		// initialize every element
		this.each(function () {
			init($(this));
		});
		return this;
	};
	// start
	$(function () {
		$('.newsticker').newsticker();
	});
})(jQuery);
 */

$('.novels .novel-item').hover(
	function () {
		$(this).addClass('active');
		if (!$(this).find('.novel-item-episodes').length) {
			$(this).append('<div class="novel-item-episodes pt-3"><div class="card bg-primary-soft rounded-sm"><div class="p-4"><h6>Bolumler</h6><div class="fs-14 mt-3"><a href="" class="mt-2 card rounded-sm py-2 px-3 text-dark"><div class=" d-flex"><span class="font-weight-medium">22 Kasim 2019</span><span class="font-weight-light mx-1">|</span><span class="flex-1 font-weight-light point">Bölüm 72: Göklere Erişmek İsteyen Süs Ördek Göklere Erişmek İsteyen Süs Ördek</span></div></a><a href="" class="mt-2 card rounded-sm py-2 px-3 text-dark"><div class=" d-flex"><span class="font-weight-medium">22 Kasim 2019</span><span class="font-weight-light mx-1">|</span><span class="flex-1 font-weight-light point">Bölüm 72: Göklere Erişmek İsteyen Süs Ördek Göklere Erişmek İsteyen Süs Ördek</span></div></a><a href="" class="mt-2 card rounded-sm py-2 px-3 text-dark"><div class=" d-flex"><span class="font-weight-medium">22 Kasim 2019</span><span class="font-weight-light mx-1">|</span><span class="flex-1 font-weight-light point">Bölüm 72: Göklere Erişmek İsteyen Süs Ördek Göklere Erişmek İsteyen Süs Ördek</span></div></a><a href="" class="mt-2 card rounded-sm py-2 px-3 text-dark"><div class=" d-flex"><span class="font-weight-medium">22 Kasim 2019</span><span class="font-weight-light mx-1">|</span><span class="flex-1 font-weight-light point">Bölüm 72: Göklere Erişmek İsteyen Süs Ördek Göklere Erişmek İsteyen Süs Ördek</span></div></a></div><a href="" class="btn btn-block mt-3 btn-primary-dark rounded-sm">Tumunu Goster</a></div></div></div>')
		}
		$('body').append('<div id="novelItemEpisodesBg" style="background:rgba(0, 0, 0, 0.5);z-index:999;position:fixed;width:100%;height:100%;left:0;top:0"></div>');
		$(this).find('.novel-item-episodes').show();
	}, function () {
		$(this).find('.novel-item-episodes').hide();
		$('#novelItemEpisodesBg').remove();
		$(this).removeClass('active');
	})

var swiper = new Swiper('.section-home-slider .swiper-container', {
	navigation: {
		nextEl: '.section-home-slider .swiper-button-next',
		prevEl: '.section-home-slider .swiper-button-prev',
	},
});
